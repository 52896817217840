import { parents, debounce } from './helpers';

/* 
 * Fallback for browsers that don't support object-fit. Grabs all elements .object-fit
 * and replaces the img with div tags that have background-image set if object-fit 
 * is not supported.
 * 
 * All .object-fit elements must have a data-position attribute which should have
 * values that are accepted by object-position & background-position
 * 
 * Example of element
 * <img class="object-fit" src="http://via.placeholder.com/350x150" data-position="right center" />
 */

if (! (window.CSS && window.CSS.supports && window.CSS.supports('object-fit:cover'))) {
  const SUPPORTS_PICTURE_ELEMENT = !!window.HTMLPictureElement;

  const objectFit = Array.from(document.querySelectorAll('.object-fit'));
  const objectFitPicture = objectFit.filter(el => el.classList.contains('object-fit--picture'));

  const replaceImg = (el) => {
    const { className, alt } = el;
    const isPicture = el.classList.contains('object-fit--picture');

    let src = el.currentSrc || el.src;
    if (isPicture && !SUPPORTS_PICTURE_ELEMENT) src = false; // just to get past the return statement

    /*
     * Most likely has data-src set on it. So don't do anything. Will most likely
     * be called again when a src prop is on it from imageLoader:loaded event
     */
    if (src == null || src === '') return;

    const position = el.getAttribute('data-position') || 'center';
    const width = parseInt(el.getAttribute('width'), 10);
    const height = parseInt(el.getAttribute('height'), 10);
    const divTag = document.createElement('DIV');

    if (src !== false) divTag.style.backgroundImage = `url('${src}')`
    divTag.style.backgroundSize = 'cover';
    divTag.style.backgroundPosition = position;

    // If img has width & height attributes, set them on the div
    if (! isNaN(width) && ! isNaN(height)) {
      divTag.style.width = `${width}px`;
      divTag.style.height = `${height}px`;
    }

    // Add appropriate aria attributes
    divTag.setAttribute('role', 'img');
    divTag.setAttribute('aria-label', alt);

    if (isPicture) {
      const pictureEl = parents(el, 'picture');
      divTag.className = pictureEl.className;
      divTag.classList.add('object-fit-picture-fallback');
      pictureEl.insertAdjacentElement('afterend', divTag);
      pictureEl.style.display = 'none';
      return;
    }
    
    divTag.className = className;

    // Get rid of object-fit class as it will be hiding the element with opacity: 0;
    divTag.classList.remove('object-fit');

    el.parentElement.insertBefore(divTag, el);
    el.parentElement.removeChild(el);
  };

  objectFit.forEach(replaceImg);

  if (objectFitPicture.length !== 0 && SUPPORTS_PICTURE_ELEMENT) {
    // the src of the picture should change instantly
    window.addEventListener('resize', debounce(() => {
      objectFitPicture.forEach((el) => {
        const pictureEl = parents(el, 'picture');
        const div = pictureEl.nextElementSibling;
        const src = el.currentSrc || el.src;
        div.style.backgroundImage = `url('${src}')`;
      });
    }), 100);
  } else if(objectFitPicture.length !== 0 && !SUPPORTS_PICTURE_ELEMENT) {
    // having to listen for picturefill to mutate the img element src attribute
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.attributeName !== 'src') return;
        const pictureEl = parents(mutation.target, 'picture');
        const div = pictureEl.nextElementSibling;
        const { src } = mutation.target;
        div.style.backgroundImage = `url('${src}')`;
      });
    });

    objectFitPicture.forEach((el) => {
      observer.observe(el, { attributes: true });
    });
  }

  // Listen into load events fired from imageLoader function
  document.addEventListener('imageLoader:loaded', _ => replaceImg(_.target));
}
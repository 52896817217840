import { clearTimeout } from "timers";

export const parents = (e, selector) => {
  let el = e;
  while(! el.matches(selector)) {
    el = el.parentElement;
    if (el == null) {
      el = false;
      break;
    }
  }
  return el;
};


export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });
}

/*
 *  Must pass in a element with the className .image-loader which follows this markup
 *  <div class="image-loader">
 *    <img data-src="placeholder.jpg" />
 *    <div class="spinner"></div>
 *  </div>
 */
export const imageLoader = (el) => {
  const img = el.querySelector('img');
  const isLoading = img.hasAttribute('data-loading'); 
  if (isLoading) return;
  const spinner = el.querySelector('.spinner');
  const src = img.getAttribute('data-src');
  // Send out event when image has loaded. Lets object-fit polyfill listen in
  const event = new Event('imageLoader:loaded', { bubbles: true, cancelable: false });
  img.setAttribute('data-loading', true);
  loadImage(src)
    .then(() => {
      img.src = src;
      img.removeAttribute('data-loading');
      img.removeAttribute('data-src');
      img.dispatchEvent(event);
      el.removeChild(spinner);
      el.classList.remove('image-loader');
    })
    .catch((err) => {
      console.log(err);
    });
};

export const debounce = (fn, timeoutDuration = 100) => {
  let timeout = false;

  return (e) => {
    // Having to use window.setTimeout & clearTimeout as browserify is bundling some functions in for some reason
    if (timeout !== false) window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      fn(e);
    }, timeoutDuration);
  };

};

/**
 * Can show stars from 0 to 5 with steps of 0.5. Doing the stars with JS as don't want to add lots of markup 
 * straight to the page. Hopefully improving initial load times slightly. 
 */
export const generateStars = (rating) => {
  const { themePublic } = window.mainArgs;
  const array = [];

  for (let i = 1; i <= 10; i++) {
    const currentRating = i * 0.5;

    const color = currentRating > rating 
    ? '#f0f3f6' // grey
    : 'currentColor'; // whatever is set in styles

    array.push(`<svg viewBox="0 0 100 190.7" style="color:${color}">
        <use xlink:href="${themePublic}/img/svgSprites/essential.svg#half-star"/>
      </svg>`);

  }

  return array;
};


export const doubleTapToGo = () => {
  const selectors = [];
  let currentItem = false;

  const key = typeof Symbol === 'function' ? Symbol('TOUCH_START') : `DOUBLE_TAP_TO_GO_TOUCH_START_${Date.now()}`;

  const addElements = selector => selectors.push(selector);

  // use similar to $($0).parents('.selector')
  const parents = (e, selector) => {
    let el = e;
    while(! el.matches(selector)) {
      el = el.parentElement;
      if (el == null) {
        el = false;
        break;
      }
    }
    return el;
  };
  
  const documentClick = (e) => {
    const { target } = e;
    if (target.hasOwnProperty(key)) {
      const lastItem = target[key][target[key].length - 1];

      /**
       * If touch start and document click are within 200ms do stuff.
       * Don't need to wait for 300ms tap delay anymore 
       * https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
       */
      if (lastItem + 200 < performance.now()) return;

      let changedCurrentItem = false;

      selectors.forEach((selector) => {
        const targetMatches = target.matches(selector);
        let parentMatches = false;
        if (!targetMatches) parentMatches = parents(target, selector);
  
        if (targetMatches || parentMatches !== false) {
          const el = targetMatches ? target : parentMatches;
          if (currentItem !== el) {
            currentItem = el;
            changedCurrentItem = true;
            e.preventDefault();
          }
        }
      });

      // Reset to false
      if (!changedCurrentItem) currentItem = false;

    }
    
  };

  if ('ontouchstart' in window) {
    document.addEventListener('touchstart', ({target}) => {
      if (! target.hasOwnProperty(key)) target[key] = [];
      target[key].push(performance.now());
    });
    document.addEventListener('click', documentClick, false);
  };

  return addElements;
};
import { parents } from './helpers';

document.addEventListener('click', ({ target }) => {
  if (typeof target.matches !== 'function') return; // Siema carousel emits the target as HTMLDocument which doesn't have the matches method
  const el = target.matches('.accordion__pane__button') 
  ? target 
  : parents(target, '.accordion__pane__button');

  if (el === false) return;
  const pane = parents(el, '.accordion__pane');
  if (pane.getAttribute('data-animating') === 'true') return;
  const isHidden = pane.getAttribute('aria-hidden') === 'true';
  const content = pane.querySelector('.accordion__pane__content');
  pane.setAttribute('data-animating', true);

  if (isHidden) {
    pane.removeAttribute('aria-hidden');
    pane.setAttribute('aria-expanded', true);
    content.style.height = 'auto';
    const { height } = content.getBoundingClientRect();
    content.style.height = '';
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        content.style.height = `${height}px`;
      });
    });
  } else {
    pane.removeAttribute('aria-expanded');
    pane.setAttribute('aria-hidden', true);
    const { height } = content.getBoundingClientRect();
    content.style.height = `${height}px`;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        content.style.height = '0px';
      });
    });
  }
});

document.addEventListener('transitionend', ({ target }) => {
  if (! target.matches('.accordion__pane__content')) return;
  const pane = parents(target, '.accordion__pane');
  if (target.style.height === '0px') target.style.height = '';
  else target.style.height = 'auto';
  pane.removeAttribute('data-animating');
});
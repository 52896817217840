const anchorLinks = document.querySelector('.anchor-links');

if (anchorLinks != null) {
  let index = 0;

  const makeID = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-');
  }

  const titlesToGet = Array
    .from(anchorLinks.querySelectorAll('.anchor-links__item a'));

  const titles = Array
    .from(document.querySelectorAll('.title'))

    // If title follows the anchor links we want to keep it
    .filter((el) => el.compareDocumentPosition(anchorLinks) === 2)

    .reduce((ret, el) => {
      const currentTitleToGet = titlesToGet[index];
      if (currentTitleToGet.textContent === el.textContent) {
        index++;
        ret.push({
          anchorLinksNav: currentTitleToGet,
          title: el,
        });
      }

      return ret;
    },[]);

  
  titles.forEach(({
    anchorLinksNav,
    title,
  }) => {
    let i = 0;
    while (i !== false) {
      const idText = i === 0 
      ? anchorLinksNav.textContent 
      : `${anchorLinksNav.textContent} ${i}`;
      const id = makeID(idText);
      const currentElWithID = document.getElementById(id);
      if (currentElWithID == null) {
        anchorLinksNav.href = `#${id}`;
        title.id = id;
        break;
      }
      i++;
    }
  });

  anchorLinks.style.opacity = 1;

}
import { parents, debounce } from './helpers';

const makeTD = () => document.createElement('TD');
const makeTR = () => document.createElement('TR');
const scrollingTable = Array.from(document.querySelectorAll('.scrolling-table'));

const getNoSectionsShowing = () => {
  // Config array for how many columns are showing at once
  const sizes = [
    {
      size: 'default',
      inView: 1,
    },
    {
      size: '56.25em',
      inView: 3,
    },
    {
      size: '75em',
      inView: 5,
    }
  ];
  const { inView } = sizes.reduce((ret, obj) => {
    const { size } = obj;
    if (size === 'default') return obj;
    if (window.matchMedia(`(min-width: ${size})`).matches) return obj;
    return ret;
  }, {});
  return inView;
};

// TODO: should cache columns if already got them once, avoiding unnecessary work.
// Gets run on next & prev clicks and also browser resizes to make sure the table isn't scrolled too much
const moveTable = ({ items, table, index:userIndex, currentlyShowing }) => {

  // only support browsers that contain these two variants of transform
  const transformProp = 'transform' in document.body.style ? 'transform' : 'webkitTransform';

  const columns = [];
  const trs = Array.from(table.querySelectorAll('tr'));

  // put column items into a columns array
  trs.forEach((tr) => {
    const tds = Array.from(tr.children);
    tds.forEach((el, i) => {
      if (i === 0) return;
      
      // 0 index is actually 2nd column. As not moving the 1st column
      if (! Array.isArray(columns[i - 1])) { 
        columns[i - 1] = [];
      }

      columns[i - 1].push(el);
    });
  });

  let index = userIndex;
  
  // If the index requested is higher than it can be go to the highest value
  if (currentlyShowing !== 1 && items - (currentlyShowing - 1) < userIndex) {
    index = items - (currentlyShowing - 1);
  }

  const translateAmount = index === 0 
  ? false 
  : 100 * index;

  // Apply/Remove transforms to every column items
  columns.forEach((column) => {
    column.forEach((item, i) => {
      if (translateAmount === false) {
        item.style[transformProp] = '';
      } else {

        // Takes into account the 4px margin on each td & th
        const marginAmount = `${-4 * index}px`; 
        
        item.style[transformProp] = `translateX(-${translateAmount}%) translateX(${marginAmount})`;
      }
    });
  });

  // set the new index on the table
  table.setAttribute('data-index', index);

};



if (scrollingTable.length !== 0) {

  let showing = getNoSectionsShowing();

  document.addEventListener('click', ({target}) => {
    if (target.matches('.scrolling-table__button')) {
      const direction = target.matches('.scrolling-table__next') ? 'next' : 'prev';
      const section = parents(target, '.scrolling-table');
      if (section === false) return;
      const table = section.querySelector('table');
      const currentIndex = parseInt(table.getAttribute('data-index'), 10);
      const items = parseInt(table.getAttribute('data-scroll-items'), 10);
      const index = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

      if (index < 0) return;
      if (index >= items) return;

      moveTable({
        items,
        table,
        index,
        currentlyShowing: showing,
      });

    } else if (target.matches('.scrolling-table__view-more button')) {
      const table = parents(target, '.scrolling-table table');
      if (table === false) return;
      table.classList.add('scrolling-table__table--view-all');
    }

  });

  scrollingTable.forEach((section) => {
    const table = section.querySelector('table');
    const tr = table.querySelector('tr');
    const items = tr.children.length;
    const tbody = table.querySelector('tbody');
    
    const viewMoreTr = makeTR();
    const viewMoreTd = makeTD();
    const getAQuoteTr = makeTR();
    const viewMoreButton = document.createElement('BUTTON');

    viewMoreButton.textContent = 'View More';

    viewMoreTd.appendChild(viewMoreButton)
    viewMoreTd.setAttribute('colspan', items);

    viewMoreTr.classList.add('scrolling-table__view-more');
    viewMoreTr.appendChild(viewMoreTd);

    for (let i = 0; i < items; i++) {
      const td = makeTD();
      if (i !== 0) {
        const button = tbody.children[0].children[i].querySelector('.button');
        if (button == null) continue;
        const newButton = button.cloneNode(true);
        td.appendChild(newButton);
      }
      getAQuoteTr.appendChild(td);
    }

    getAQuoteTr.classList.add('scrolling-table__get-quote');


    tbody.appendChild(viewMoreTr);
    tbody.appendChild(getAQuoteTr);


    // 1st column is not moving so minus 1
    table.setAttribute('data-scroll-items', items - 1);

    table.setAttribute('data-index', 0);
  });

  // waits 50ms after last resize event listener has fired before running function
  window.addEventListener('resize', debounce(() => {
    showing = getNoSectionsShowing();
    scrollingTable.forEach((section) => {
      const table = section.querySelector('table');
      const items = table.getAttribute('data-scroll-items');
      const index = table.getAttribute('data-index');

      moveTable({
        items,
        table,
        index,
        currentlyShowing: showing,
      });

    });
    
  }, 50));

}
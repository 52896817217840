import { doubleTapToGo } from './helpers';

const addElements = doubleTapToGo();
const nav = document.querySelector('.header__nav');


addElements('.header__nav__menu-item--has-children');

if (nav.length !== 0) {

  // Only want items that have a depth of 1

  Array.from(document.querySelectorAll('.header__nav__sub-menu--depth-1'))
    .forEach((containerEl) => {

      const stackItems = Array
        .from(containerEl.querySelectorAll('.stack.header__nav__menu-item--depth-1'));

      if (stackItems.length !== 0) {

        const newLi = document.createElement('LI');
        newLi.className = 'header__nav__menu__item header__nav__menu__item--stack header__nav__menu-item--depth-1';

        stackItems[0].parentElement.insertBefore(newLi, stackItems[0]);

        stackItems.forEach((el) => {
          newLi.appendChild(el);
        });

      }

    });
  
  const menuField = document.querySelector('.menu-field');
  if (menuField != null) {

    Array
      .from(document.querySelectorAll('.header__nav__sub-menu--depth-1'))
      .forEach((el) => {
         const node = menuField.cloneNode(true);
         el.appendChild(node.children[0]);
      });

  }
  




  const { body } = document;

  Array
    .from(document.querySelectorAll('.header__nav__menu-item--depth-0'))
    .forEach((el) => {
      el.addEventListener('mouseenter', () => {
        body.classList.add('hide-page');
      });

      el.addEventListener('mouseleave', () => {
        body.classList.remove('hide-page');
      });
    });

    const menuOffer = document.querySelector('.menu-offer');
    if (menuOffer != null) {
  
      Array
        .from(nav.querySelectorAll('.header__nav__sub-menu--depth-1'))
        .forEach((el) => {
          const node = menuOffer.cloneNode(true);
          node.style.display = '';
          el.appendChild(node);
        });
    }

}
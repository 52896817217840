import Siema from 'siema';
import { debounce } from './helpers';

const initCarousel = () => {
  const carousels = [];
  const settings = [
    {
      breakpoint: '0em',
      settings: {
        perPage: 1,
        loop: true,
      },
    },
    {
      breakpoint: '48em',
      settings: {
        perPage: 2,
        loop: true,
      },
    },
    {
      breakpoint: '77.5em',
      settings: {
        perPage: 4,
        loop: true,
      },
    },
  ];
  
  const getCurrentSetting = () => {
    return settings.reduce((ret, item) => {
      if (window.matchMedia(`(min-width: ${item.breakpoint})`).matches) {
        return item.settings;
      }
      return ret;
    }, {});
  };

  const siema = Array.from(document.querySelectorAll('.siema'))

  let setting = getCurrentSetting();

  siema.forEach((carouselEl) => {
    const newSettings = Object.assign({}, setting, { selector: carouselEl });
    const carousel = new Siema(newSettings);
    carouselEl.GG_SIEMA = carousel;
    carousels.push(carousel);
  });

  window.addEventListener('resize', debounce(() => {
    const newSetting = getCurrentSetting();

    // As the variables are just references to the settings in the settings array we can do a compare like dis
    if (newSetting === setting) return; 

    setting = newSetting;
    carousels.forEach((carousel, i) => {
      carousel.destroy(true, () => {
        const carouselEl = siema[i];
        // console.log(carouselEl);
        const newSettings = Object.assign({}, setting, { selector: carouselEl });
        carousels[i] = new Siema(newSettings);
        carouselEl.GG_SIEMA = carousels[i];
      });
    });
  }, 100));

  document.addEventListener('click', ({ target }) => {
    if (typeof target.matches !== 'function') return;
    const isPrev = target.matches('.carousel-section__prev');
    const isNext = target.matches('.carousel-section__next');
    if (!isPrev && !isNext) return;
    const carouselEl = target.parentElement.querySelector('.siema');
    if (isPrev) {
      carouselEl.GG_SIEMA.prev();
    } else if (isNext) {
      carouselEl.GG_SIEMA.next();
    }
  });

}


if (Array.from(document.querySelectorAll('.siema')).length !== 0) { 
  initCarousel();
}

Array
  .from(document.querySelectorAll('.carousel-section'))
  .forEach((el) => {
    const imageSlide = el.querySelector('.carousel-section__slide__image');
    if (imageSlide != null) el.classList.add('carousel-section--images');
  });
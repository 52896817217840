// Contains logic for the reviews module
import { lory } from 'lory.js';
import { generateStars } from './helpers';

const reviews = document.querySelector('.reviews');
if (reviews != null) {

  const slider = reviews.querySelector('.reviews__carousel');
  const ratings = Array.from(reviews.querySelectorAll('.reviews__slide__rating'));
  const { themePublic } = window.mainArgs;


  ratings.forEach((el) => {
    const rating = parseFloat(el.getAttribute('data-rating'), 10);
    if (isNaN(rating)) return;
    const html = generateStars(rating)
      .map((html) => {
        return `<div class="reviews__slide__rating__icon">${html}</div>`;
      })
      .join('');
    el.innerHTML = html;
  });
  
  lory(slider, {
    infinite: 1,
    classNameFrame: 'carousel__frame',
    classNameSlideContainer: 'carousel__slides',
    classNamePrevCtrl: 'carousel__prev',
    classNameNextCtrl: 'carousel__next',
  });

}


import { parents } from './helpers';

const footerSubscribe = document.querySelector('.footer__subscribe');
const footerForm = document.querySelector('.footer__form');
const formInput = Array.from(document.querySelectorAll('.form__item input'));
const footerFormButton = footerForm.querySelector('button');
if (footerForm != null) {
  const selectField = footerForm.querySelector('#s-title-select');
  const hiddenTitleField = footerForm.querySelector('#s-title');
  // Adds title value to the hidden field
  selectField.addEventListener('change', ({target}) => {
    hiddenTitleField.value = target.value;
  });

  footerForm.addEventListener('submit', (e) => {
    e.preventDefault();

    footerFormButton.disabled = true;

    const encodedData = [
      footerForm['First_Name'],
      footerForm['Surname'],
      footerForm['email'],
      footerForm['Title'],
    ].map((input) => {
      const { name, value } = input;
      return encodeURIComponent(name) + '=' + encodeURIComponent(value);
    }).join('&');

    const { homeURL } = window.mainArgs;
    const slash = homeURL[homeURL.length - 1] === '/' ? '' : '/';
    const endpoint = window.mainArgs.homeURL + slash + 'wp-json/newsletter/v1/subscribe/';

    fetch(endpoint, {
      method: 'POST',
      body: encodedData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(_ => _.text())
    .then((response) => {
      const res = response.replace(/"/g, '');
      if (res !== 'OK') throw new Error(res);
      footerSubscribe.classList.add('footer__subscribe--message');
      footerSubscribe.classList.add('footer__subscribe--message-success');
    })
    .catch((err) => {
      const errorContainer = document.querySelector('.footer__subscribe__message--error');

      if (err.message === 'ERROR: No email or mobile given') {
        errorContainer.innerHTML = '<p>No email address provided.</p>';
      } else if (err.message === 'ERROR: Value provided is not a valid email address [Email address]') {
        errorContainer.innerHTML = '<p>Email provided is not a valid email address.</p>';
      } else {
        errorContainer.innerHTML = '<p>An unknown error occoured.</p>';
      }

      footerSubscribe.classList.add('footer__subscribe--message');
      footerSubscribe.classList.add('footer__subscribe--message-error');
    });
  });
}



document.addEventListener('submit', (e) => {
  const { target } = e;
  if (target.matches('.form--validate')) {
    const valid = target.checkValidity();
    if (valid === false) e.preventDefault();
  }
});


formInput.forEach((el) => {
  const { value, type } = el;
  if (type === 'checkbox' || type === 'radio') return;
  if (value.trim() !== '') {
    const formItem = parents(el, '.form__item');
    if (formItem === false) return;
    formItem.classList.add('.form__item--open');
  }
});

document.addEventListener('change', ({target}) => {
  if (target.matches('.form__item input')) {
    const { value, type } = target;
    if (type === 'radio' || type === 'checkbox') return;

    const formItem = parents(target, '.form__item');
    if (formItem === false) return;
    const val = value.trim();
    if (val === '') formItem.classList.remove('form__item--open');
    else formItem.classList.add('form__item--open');
  }
});
// Contains logic for the star ratings on the reviews "archive"
import { generateStars } from './helpers';

const totalRating = document.querySelector('.review-total__rating');
const individualRatings = Array.from(document.querySelectorAll('.review-post__rating'));
const container = document.querySelector('.review-posts__container');
const addStars = (el) => {
  const rating = parseInt(el.getAttribute('data-rating'), 10);
  el.innerHTML = generateStars(rating).join('');
}

if (totalRating != null) addStars(totalRating);
if (individualRatings.length !== 0) individualRatings.forEach(addStars);

let noBackgroundAdded = 0;

const observer = new MutationObserver((mutationList) => {
  // console.log('observer fired');
  mutationList.forEach((mutation) => {
    // console.log(mutation);
    if (mutation.type === 'childList') {
      let shouldAddBackgrounds = false;
      Array.from(mutation.addedNodes).forEach((node) => {
        if (node.matches('.review-post')) {
          addStars(node.querySelector('.review-post__rating'));
          shouldAddBackgrounds = true;
        }
      });
      if (shouldAddBackgrounds) {
        const total = container.querySelectorAll('.review-post').length;
        const perPage = 8;
        const loop = Math.ceil(total / (perPage / 2));
        addBackgrounds(container, loop, noBackgroundAdded);
        
        /**
         * Move the backgrounds to the bottom of the container. So any nth-child selectors
         * in the CSS are not effected.
         */
        Array.from(container.querySelectorAll('.review-posts__container__background'))
          .forEach((el) => {
            container.appendChild(el);
          });
      }
    }
  });
});


/**
 * Adds backgrounds to the correct positions for the .review-posts__container element
 * 
 * @param {object} container - HTML element
 * @param {number} loop - How many times the for loop should loop
 * @param {number} start - The starting index for the for loop
 */
const addBackgrounds = (container, loop, start = 0) => {
  const ieIE = 'msFlex' in document.body.style;

  for (let i = start; i < loop; i++) {
    const backgroundItem = document.createElement('DIV');
    backgroundItem.classList.add('review-posts__container__background');
    const topHalf = i % 2 === 0;
    let rowStart = (i + 1) * 2
    let rowEnd = rowStart + 1;

    if (ieIE) {
      backgroundItem.style.msGridRow = rowStart;
      backgroundItem.style.msGridRowSpan = 1;
    } else {
      backgroundItem.style.gridRowStart = rowStart;
      backgroundItem.style.gridRowEnd = rowStart + 1;
    }

    container.appendChild(backgroundItem);
  }

  noBackgroundAdded = noBackgroundAdded + loop;
}

if (container) {
  const total = container.children.length;
  const perPage = 8;
  const loop = Math.ceil(total / (perPage / 2));
  observer.observe(container, { childList: true});

  const tempObserver = new MutationObserver((mutationList, observer) => {
    const item = mutationList[0];
    observer.disconnect();
    addBackgrounds(container, loop, 0);
  });

  tempObserver.observe(container.firstElementChild, { attributes: true });
}

// 6 lines
import { imageLoader } from './helpers';

const tabsContainer = document.querySelector('.tabs');

if (tabsContainer != null) {
  const controls = tabsContainer.querySelector('.tabs__controls');
  const content = tabsContainer.querySelector('.tabs__content');
  const tabs = Array.from(content.querySelectorAll('.tabs__tab'));
  const buttons = Array.from(controls.querySelectorAll('button'));
  let activeKey = false;

  const getValidKey = (key, obj, i = 0) => {
    const newKey = i === 0 ? key : `${key}-${i}`;
    if (obj.hasOwnProperty(newKey)) return getValidKey(key, obj, ++i);
    return {
      key: newKey,
      hasChanged: i !== 0,
    };
  };

  /*
   * Makes sure there are no duplicate keys in the returned objects
   * and will also modify the elements keys if they are duplicates
   * of another
   */
  const keyCheck = (ret, el, i) => {
    const { key, hasChanged } = getValidKey(el.getAttribute('data-tab'), ret);
    if (i === 0 && activeKey === false) activeKey = key;
    if (hasChanged) el.setAttribute('data-tab', key);
    ret[key] = el;
    return ret;
  };

  const buttonKeys = buttons.reduce(keyCheck, {});
  const tabKeys = tabs.reduce(keyCheck, {});


  buttons.forEach((el, i) => {
    el.disabled = false;
    if (i === 0) {
      el.classList.add('tabs__controls__active');
    }
  });

  tabs[0].classList.add('tabs__tab--active');

  controls.addEventListener('click', ({ target }) => {
    if (! target.matches('.tabs__controls button')) return;
    const dataTab = target.getAttribute('data-tab');
    if (dataTab == null || dataTab === activeKey) return;
    const oldButton = buttonKeys[activeKey];
    const oldTab = tabKeys[activeKey];
    oldTab.classList.remove('tabs__tab--active');
    oldButton.classList.remove('tabs__controls__active');
    activeKey = dataTab;
    const button = buttonKeys[activeKey];
    const tab = tabKeys[activeKey];
    tab.classList.add('tabs__tab--active');
    button.classList.add('tabs__controls__active');
    Array.from(tab.querySelectorAll('.image-loader'))
      .forEach(el => imageLoader(el));
  });

  tabsContainer.classList.remove('tabs--loading');
}
import FontFaceObserver from 'fontfaceobserver';

import './polyfills';
import './mobileNav';
import './reviews';
import './reviews-archive';
import './accordion';
import './tabs';
import './object-fit';
import './form';
import './scrollingTable';
import './nav';
import './anchor-links';
import './carousel-section';
import './liveChat';
import './policyBox';
import './gravityForm';


const googleFont = new FontFaceObserver('Montserrat');

googleFont.load().then(() => {
  document.documentElement.className += ' fonts-loaded';
});

let gravityFormsRecaptchaPoller = setInterval(() => {
  if(! window.grecaptcha || ! window.grecaptcha.render) return;
  window.renderRecaptcha();
  clearInterval(gravityFormsRecaptchaPoller);
}, 100);
import 'mutationobserver-shim'; // need because of ie 10 support
import 'mdn-polyfills/Array.from'; 
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Object.assign';

import svg4everybody from 'svg4everybody';
import 'picturefill';

require('es6-promise/auto');

// makes svg4everybody run after everything else
setTimeout(() => {
  svg4everybody();
}, 0);
const content = Array.from(document.querySelectorAll('.policy-box__content'));

content.forEach((el) => {
  const icon = el.previousElementSibling;
  el.addEventListener('mouseenter', (e) => {
    icon.classList.add('hover');
  });

  el.addEventListener('mouseleave', (e) => {
    icon.classList.remove('hover');
  });
});
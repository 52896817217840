import { parents } from './helpers';

(() => {

  if (window.location.hash.indexOf('#gf') === 0) {
    const form = document.querySelector(window.location.hash);
    if (form == null) return;
    const hasConfirmationMessage = form.querySelector('.gform_confirmation_message') != null;
    const tab = parents(form, '.tabs__tab');
    if (tab === false) return;
    const tabID = tab.getAttribute('data-tab');
    const tabsParent = parents(form, '.tabs');
    const currentActiveButton = tabsParent.querySelector('.tabs__controls__active');
    const currentActiveTab = tabsParent.querySelector('.tabs__tab--active');
    if (currentActiveButton != null) currentActiveButton.classList.remove('tabs__controls__active');
    if (currentActiveTab != null) currentActiveTab.classList.remove('tabs__tab--active');

    tab.classList.add('tabs__tab--active');
    tabsParent.querySelector(`button[data-tab="${tabID}"]`).classList.add('tabs__controls__active');
  }

})();


import { parents } from './helpers';

const headerToggle = document.querySelector('.header__toggle');

// Contains the buttons
const mobileButtons = document.querySelector('.header__desktop').cloneNode(true);
const mobileNav = document.querySelector('.header__nav').cloneNode(true);
const aside = document.createElement('aside');

mobileButtons.className = 'mobile-menu__buttons';

mobileNav.className = 'mobile-menu__nav';
aside.className = 'mobile-menu';

Array
  .from(mobileNav.querySelectorAll('*'))
  .forEach((el) => {
    // SVG doesn't have a className property like other elements so get the class attribute
    const ogClassName = el.getAttribute('class');
    if (typeof ogClassName !== 'string' || ogClassName === '') return;
    const className = ogClassName.replace(/header__nav__/g, 'mobile-menu__nav__');
    el.setAttribute('class', className);
  });

aside.appendChild(mobileNav);
aside.appendChild(mobileButtons);
document.body.appendChild(aside);


document.addEventListener('click', (e) => {
  const { target } = e;
  if (typeof target.matches !== 'function') return;

  const li = parents(target, 'li');

  if (li === false) return;
  
  /**
   * When getting the parents we only want to get the first containing li but
   * that li might not have a ul. So we return if it doesn't have this class
   */
  if (! li.matches('.mobile-menu__nav__menu-item--has-children')) return;

  e.preventDefault();
  li.classList.toggle('mobile-menu__nav__menu-item--open');
});

headerToggle.addEventListener('click', () => {
  aside.classList.toggle('mobile-menu--open');
  document.body.classList.toggle('no-scroll');
});

headerToggle.style.opacity = 1;

